<template>
    <div>
        <step-bar :active="2" @clickStep="clickStep"></step-bar>
        <el-card class="card-pub-notice card-ext-center-title">
            <div slot="header" class="notice_title">
                <span>新区配售电有限公司供电方案审批流程单</span>
            </div>
            <div class="commonNotice">
                <ex-card headerName="客户基本信息">
                    <div class="commonNotice">
                        <div class="ulbox">
                            <el-row :gutter="24" v-for="(item,index) in customerInfo" :key="index">
                                <el-col :span="6" style="text-align:right;">
                                    <span>{{item.name}}:</span>
                                </el-col>
                                <el-col :span="18" style="text-align:left;">
                                    <span>{{item.value}}</span>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </ex-card>
                <ex-card headerName="电源容量信息">
                    <div class="commonNotice">
                        <div class="ulbox">
                            <el-row :gutter="24" v-for="(item,index) in electricCapacity" :key="index">
                                <el-col :span="6" style="text-align:right;">
                                    <span>{{item.name}}:</span>
                                </el-col>
                                <el-col :span="18" style="text-align:left;">
                                    <span>{{item.value}}</span>
                                </el-col>
                            </el-row>
                            <el-row :gutter="24" v-for="(item,index) in electricPlanMeteringEntities" :key="index">
                                <el-col :span="6" style="text-align:right;">
                                    <span>电源点{{index+1}}:</span>
                                </el-col>
                                <el-col :span="18" style="text-align:left;">
                                    <span>{{item.powerAp}}</span>
                                </el-col>
                                <el-col :span="18" :offset="6">
                                    <el-row :gutter="24">
                                        <el-col :span="6" style="text-align:right;">
                                            <span>供电源(主/备):</span>
                                        </el-col>
                                        <el-col :span="18" style="text-align:left;">
                                            <span>{{item.isInvestmentInterface==1?'主':'备'}}</span>
                                        </el-col>
                                        <el-col :span="6" style="text-align:right;">
                                            <span>供电容量:</span>
                                        </el-col>
                                        <el-col :span="18" style="text-align:left;">
                                            <span>{{item.supplyCapacity}}(kVA)</span>
                                        </el-col>
                                    </el-row>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </ex-card>
                <ex-card headerName="计量配置">
                    <div class="commonNotice">
                        <div class="ulbox">
                            <template v-for="(item,index) in electricPlanMeteringEntities">
                                <el-row :gutter="24" :key="index+0.1">
                                    <el-col :span="6" style="text-align:right;">
                                        <span>计量点{{index+1}}</span>
                                    </el-col>
                                    <el-col :span="18" style="text-align:left;">
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24" :key="index+0.2">
                                    <el-col :span="6" style="text-align:right;">
                                        <span>计量点号:</span>
                                    </el-col>
                                    <el-col :span="18" style="text-align:left;">
                                        <span>{{item.meteringNo}}</span>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24" :key="index+0.3">
                                    <el-col :span="6" style="text-align:right;">
                                        <span>计量方式采用:</span>
                                    </el-col>
                                    <el-col :span="18" style="text-align:left;">
                                        <span>{{getTxt(keyValues.meteringType, item.meteringType)}}</span>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24" :key="index+0.4">
                                    <el-col :span="6" style="text-align:right;">
                                        <span>计量点位置:</span>
                                    </el-col>
                                    <el-col :span="18" style="text-align:left;">
                                        <span>{{item.meteringPos}}</span>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24" :key="index+0.5">
                                    <el-col :span="6" style="text-align:right;">
                                        <span>计量装配配置:</span>
                                    </el-col>
                                    <el-col :span="18" style="text-align:left;">
                                        <span>电流互感器变比为:{{item.meteringDeviceA}}</span>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24" :key="index+0.6" style="margin-bottom:12px">
                                    <el-col :offset="6" :span="18" style="text-align:left;">
                                        <span>电压互感器变比为:{{item.meteringDeviceV}}</span>
                                    </el-col>
                                </el-row>
                            </template>
                        </div>
                    </div>
                </ex-card>
                <ex-card headerName="电价执行">
                    <div class="commonNotice">
                        <div class="ulbox">
                            <template v-for="(item,index) in electricPlanMeteringEntities">
                                <el-row :gutter="24" :key="index+0.1">
                                    <el-col :span="6" style="text-align:right;">
                                        <span>计量点{{index+1}}执行电价:</span>
                                    </el-col>
                                    <el-col :span="18" style="text-align:left;">
                                        <span>{{item.businessName}}:{{item.businessSupplyVoltage}}(kVA)</span>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24" :key="index+0.2" v-if="item.businessName=='大工业'">
                                    <el-col :offset="6" :span="18" style="text-align:left;">
                                        <span>基本电费收取方式:{{getTxt(keyValues.chargeCollectType, item.chargeCollectType)}}</span>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24" :key="index+0.3">
                                    <el-col :span="6" style="text-align:right;">
                                        <span>功率因素执行标准:</span>
                                    </el-col>
                                    <el-col :span="18" style="text-align:left;">
                                        <span>{{item.powerFactorStandard===0?'不执行':item.powerFactorStandard}}</span>
                                    </el-col>
                                </el-row>
                                <el-row :gutter="24" :key="index+0.4" style="margin-bottom:12px">
                                    <el-col :span="6" style="text-align:right;">
                                        <span>是否分时:</span>
                                    </el-col>
                                    <el-col :span="18" style="text-align:left;">
                                        <span>{{item.isTimeDivision==0?'是':'否'}}</span>
                                    </el-col>
                                </el-row>
                            </template>
                        </div>
                    </div>
                </ex-card>
                <ex-card headerName="重要负荷应急电源配置">
                    <div class="commonNotice">
                        <div class="ulbox">
                            <el-row :gutter="24">
                                <el-col :span="24" style="text-align:left;">
                                    <span>{{importantLoadPower}}</span>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </ex-card>
                <ex-card headerName="业扩费用">
                    <div class="commonNotice">
                        <div class="ulbox">
                            <el-row :gutter="24" v-if="electricPlanEntity.isBusinessFee==1">
                                <el-col :span="6" style="text-align:right;">
                                    <span>高可靠性供电费:</span>
                                </el-col>
                                <el-col :span="18" style="text-align:left;">
                                    <span>每kVA/kW:{{electricPlanEntity.businessFee}}(元)</span>
                                </el-col>
                            </el-row>
                            <el-row :gutter="24" v-if="electricPlanEntity.isBusinessFee==1">
                                <el-col :offset="6" :span="18" style="text-align:left;">
                                    <span>合计:{{electricPlanEntity.businessTotalFee}}(元)</span>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </ex-card>
                <ex-card headerName="工程设计">
                    <div class="commonNotice">
                        <div class="ulbox">
                            <el-row :gutter="24" v-for="(item,index) in design" :key="index">
                                <el-col :span="6" style="text-align:right;">
                                    <span>{{item.name}}:</span>
                                </el-col>
                                <el-col :span="18" style="text-align:left;">
                                    <span>{{item.value}}</span>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </ex-card>
                <ex-card headerName="中间检查">
                    <div class="commonNotice">
                        <div class="ulbox">
                            <el-row :gutter="24">
                                <el-col :span="24" style="text-align:left;">
                                    <span>{{middleCheck}}</span>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </ex-card>
                <ex-card headerName="资产移交">
                    <div class="commonNotice">
                        <div class="ulbox">
                            <el-row :gutter="24" v-for="(item,index) in assetTransfer" :key="index">
                                <el-col :span="6" style="text-align:right;">
                                    <span>{{item.name}}:</span>
                                </el-col>
                                <el-col :span="18" style="text-align:left;">
                                    <span>{{item.value}}</span>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </ex-card>
                <ex-card headerName="竣工检验">
                    <div class="commonNotice">
                        <div class="ulbox">
                            <el-row :gutter="24">
                                <el-col :span="24" style="text-align:left;">
                                    <span>配电工程完成施工时请及时提交竣工检验申请。</span>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </ex-card>
                <ex-card headerName="产权分界点示意图">
                    <div class="commonNotice">
                        <div class="ulbox">
                            <el-row :gutter="24">
                                <el-col :span="24" style="text-align:left;">
                                    <ex-uploader ref="form10" v-model="supplyDiagramFiles" :disabled=true></ex-uploader>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </ex-card>
                <ex-card headerName="是否带电作业">
                    <div class="commonNotice">
                        <div class="ulbox">
                            <el-row :gutter="24" v-for="(item,index) in hotLineWork" :key="index">
                                <el-col :span="6" style="text-align:right;">
                                    <span>{{item.name}}:</span>
                                </el-col>
                                <el-col :span="18" style="text-align:left;">
                                    <span>{{item.value}}</span>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </ex-card>
                <ex-card headerName="审核记录" v-if="reviewRecords.length>0">
                    <div class="commonNotice">
                    <div class="ulbox">
                        <el-row :gutter="24">
                            <el-col :span="24" style="text-align:left;border-bottom: 1px solid #2b5479;" v-for="(item,index) in reviewRecords" :key="index">
                                <p>{{$dayjs(item.reviewTime).format('YYYY-MM-DD HH:mm')}}  审核: <span :style="{color:item.reviewStatus==1?'green':'#ff7d7d',margin:'0 5px'}">{{item.reviewStatus==1?' 通过 ':' 不通过 !'}} <i v-if="item.reviewStatus!==1" class="el-icon-close no-pass-icon"></i></span></p>
                                <p>{{item.reviewNote}}</p>
                                <p style="text-align:right">审核人: {{item.reviewBy}}</p>
                            </el-col>
                        </el-row>
                    </div>
                    </div>
                </ex-card>
                <ex-card headerName="审核意见">
                    <div class="commonNotice">
                    <div class="ulbox">
                        <el-row :gutter="24">
                            <el-col :span="24" style="text-align:left;">
                                <el-input type="textarea" v-model="reviewForm.reviewNote" placeholder="请输入审核意见" :rows="5"></el-input>
                            </el-col>
                        </el-row>
                    </div>
                    </div>
                </ex-card>
            </div>
        </el-card>

        <el-row :gutter="24" class="submit-btn-group">
            <el-col :offset="8" :span="8">
                <el-button @click="cancel">取消</el-button>
                <el-button type="danger" @click="reject">驳回</el-button>
                <el-button @click="pass">通过</el-button>
            </el-col>
        </el-row>

    </div>
</template>

<script>
import axios from '@/client/axios';
import StepBar from '@/components/StepBar/index';
export default {
    name: 'ElectricPlanReview',
    components: {
        StepBar
    },
    props: {
        id: {
            type: [String, Number],
            default: '0'
        },
        workflowId: {
            type: [String, Number],
            default: '0'
        },
        businessFormKey: {
            type: String,
            default: ''
        },
        workflowTaskId: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            customerInfo: [],
            electricCapacity: [],
            electricPlanMeteringEntities: [],
            importantLoadPower: '',
            electricPlanEntity: {},
            middleCheck: '',
            design: [],
            meterings: [],
            supplyDiagramFiles: [],
            assetTransfer: [],
            hotLineWork: [],
            reviewRecords: [],
            reviewForm: {
                reviewNote: '',
                reviewStatus: 0,
            },
            keyValues: {
                customerIdentity: [],
                customerIndustry: [],
                electricSubstationAddr: [],
                electricAccessWay: [],
                elecNature: [],
                meteringType: [],
                chargeCollectType: [],
                importantLoadPower: [],
                designScope: [],
                middleCheckItems: []
            },
        };
    },
    methods: {
        getTxt(source, value) {
            let items = source.filter(e => parseInt(e.itemKey) === value);
            return items.length > 0 ? items[0].itemValue : value;
        },
        async bindKeyValues() {
            const dictMap = [
                {parentCode: 'customer_identity', listName: 'customerIdentity'},
                {parentCode: 'customer_industry', listName: 'customerIndustry'},
                {parentCode: 'electric_substation_addr', listName: 'electricSubstationAddr'},
                {parentCode: 'electric_access_way', listName: 'electricAccessWay'},
                {parentCode: 'elec_nature', listName: 'elecNature'},
                {parentCode: 'metering_type', listName: 'meteringType'},
                {parentCode: 'charge_collect_type', listName: 'chargeCollectType'},
                {parentCode: 'important_load_power', listName: 'importantLoadPower'},
                {parentCode: 'design_scope', listName: 'designScope'},
                {parentCode: 'middle_check_item', listName: 'middleCheckItems'},
            ];
            let res = await this.$client.getDicDataArr(dictMap.map((it) => it.parentCode));
            let {data: resData} = res;
            for (let key in resData) {
                let data = resData[key];
                let dict = dictMap.filter((it) => it.parentCode === key)[0];
                this.keyValues[dict.listName] = data;
            }

            // const data = await Promise.all([
            //     this.$client.getDicData('customer_identity'),
            //     this.$client.getDicData('customer_industry'),
            //     this.$client.getDicData('electric_substation_addr'),
            //     this.$client.getDicData('electric_access_way'),
            //     this.$client.getDicData('elec_nature'),
            //     this.$client.getDicData('metering_type'),
            //     this.$client.getDicData('charge_collect_type'),
            //     this.$client.getDicData('important_load_power'),
            //     this.$client.getDicData('design_scope'),
            //     this.$client.getDicData('middle_check_item'),
            // ]);
            // this.keyValues.customerIdentity = data[0].data || [];
            // this.keyValues.customerIndustry = data[1].data || [];
            // this.keyValues.electricSubstationAddr = data[2].data || [];
            // this.keyValues.electricAccessWay = data[3].data || [];
            // this.keyValues.elecNature = data[4].data || [];
            // this.keyValues.meteringType = data[5].data || [];
            // this.keyValues.chargeCollectType = data[6].data || [];
            // this.keyValues.importantLoadPower = data[7].data || [];
            // this.keyValues.designScope = data[8].data || [];
            // this.keyValues.middleCheckItems = data[9].data || [];
        },
        loadData() {
            // todo 新接口数据缺失，未替换
            this.$client.getElectricPlanInfo(this.id).then(data => {
                // 客户基本信息
                this.customerInfo.push({name: '户号(客户编号)', value: data.data.customerNumber});
                this.customerInfo.push({name: '工单号', value: data.data.businessNumber});
                this.customerInfo.push({name: '客户名称', value: data.data.customerName});
                this.customerInfo.push({name: '用电地址', value: data.data.useAddr});
                this.customerInfo.push({name: '经办人', value: data.data.agent});
                this.customerInfo.push({name: '联系电话', value: data.data.agentTel});

                // 电源容量信息
                let items = this.keyValues.electricSubstationAddr.filter(e => parseInt(e.itemKey) === data.data.electricSubstationAddr);
                this.electricCapacity.push({name: '变电所所址型式', value: items.length > 0 ? items[0].itemValue : data.data.electricSubstationAddr});
                items = this.keyValues.electricAccessWay.filter(e => parseInt(e.itemKey) === data.data.electricAccessWay);
                this.electricCapacity.push({name: '高压侧电气主接线方式', value: items.length > 0 ? items[0].itemValue : data.data.electricAccessWay});
                this.electricCapacity.push({name: '设备容量详细情况', value: JSON.parse(data.data.highVoltageMotors).map(n => n.transformerCapacity + '(kVA)变压器' + n.num + '台').join(';')});

                // todo 高压电机容量字段未知
                // this.electricCapacity.push({name: '高压电机容量', value: data.data.electricPlanEntity.electricSubstationAddr});
                this.electricCapacity.push({name: '合计总容量', value: data.data.totalCapacity + '(kW)'});
                this.electricCapacity.push({name: '报装容量', value: '原装:' + data.data.oldCapacity + ';新装:' + data.data.updateCapacity + '(kVA);总计:' + data.data.totalCapacity + '(kVA)'});
                items = this.keyValues.elecNature.filter(e => parseInt(e.itemKey) === data.data.elecNature);
                this.electricCapacity.push({name: '用电性质', value: data.data.elecNature});
                // todo 供电电压字段未知
                // this.electricCapacity.push({name: '供电电压', value: data.data.electricPlanEntity.supplyVoltage});
                this.electricPlanMeteringEntities = data.data.electricPlanMeterings;

                // 重要负荷应急电源配置
                items = this.keyValues.importantLoadPower.filter(e => parseInt(e.itemKey) === data.data.importantLoadPower);
                this.importantLoadPower = items.length > 0 ? items[0].itemValue : data.data.importantLoadPower;

                this.electricPlanEntity = data.data;

                // 工程设计
                items = this.keyValues.designScope.filter(e => data.data.designScopes.indexOf(e.itemKey) > -1);
                this.design.push({name: '设计范围', value: items.length > 0 ? items.map(e => e.itemValue).join(';') : data.data.designScopes});
                this.design.push({name: '业扩典设参考图号', value: data.data.designPicNo});

                // 中间检查
                this.middleCheck = this.electricPlanEntity.isMiddleCheck === 0 ? '免检查' : '须检查';
                if (this.electricPlanEntity.isMiddleCheck === 0) {
                    this.middleCheck += '(' + this.keyValues.middleCheckItems.filter(e => data.data.middleCheckItems.indexOf(e.itemKey) > -1).map(e => e.itemValue).join(';') + ')';
                }

                // 资产移交
                this.assetTransfer.push({name: '设备是否属于资产移交范围', value: this.electricPlanEntity.assetTransferScope === 1 ? '是' : '否'});
                this.assetTransfer.push({name: '设备是否愿意移交', value: this.electricPlanEntity.isAssetTransfer === 1 ? '是(需签订协议)' : '否'});

                this.supplyDiagramFiles = data.data.supplyDiagramFiles;

                // 是否带电作业
                this.hotLineWork.push({name: '是否带电作业', value: data.data.isHotLineWork === 1 ? '是' : '否'});
                if (data.data.isHotLineWork === 1) {
                    this.hotLineWork.push({name: '计划用电时间', value: this.$dayjs(data.data.plannedDate).format('YYYY年MM月DD日')});
                }
            });
            this.$client.getReviewRecords({
                workflowId: this.workflowId,
                reviewFormKey: this.businessFormKey
            }).then((r) => {
                this.reviewRecords = r.data;
            });
        },
        cancel() {
            this.$confirm('确认取消？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$emit('closePanel', 2);
            });
        },
        reject() {
            this.submitReview(0);
        },
        pass() {
            this.submitReview(1);
        },
        submitReview(status) {
            this.reviewForm.reviewStatus = status;
            this.$client.completeReviewTask(this.workflowTaskId, this.reviewForm).then((r) => {
                this.$message({
                    message: '操作成功',
                    type: 'success',
                });
                this.$emit('closePanel', 2);
            });
        },
        clickStep(index) {
            this.$emit('clickStep', index);
        }
    },
    async created() {
        await this.bindKeyValues();
        await this.loadData();
    }
};
</script>
<style lang="less" scoped>
.card-ext-center-title {
    .commonNotice {
        line-height: 2.1;
    }
}
</style>
